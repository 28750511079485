import TransitionLink from "gatsby-plugin-transition-link";
import { TimelineLite } from "gsap/all";
// NEEDED FOR GSAP TO WORK ON PRODUCTION
import CSSPlugin from "gsap/CSSPlugin";
// @ts-ignore
const C = CSSPlugin;

import React, { useEffect, useRef } from "react";
import SEO from "../components/seo";

const NotFoundPage = () => {
  // REFS
  const $page = useRef(null);

  useEffect(() => {
    enterAnimation();
  }, []);

  const enterAnimation = () => {
    const tl = new TimelineLite({
      delay: 0.2,
    });

    tl.to($page.current, 0.2, { alpha: 1 });
  };

  return (
    <>
      <SEO title="404: Not found" />
      <div ref={$page} className={"page-404-container page-container "} style={{ opacity: 0 }}>
        <div className="content-404">
          <div className="svg-container-404">
            <svg className="svg-404" viewBox="0 0 291.15 129.784">
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#404"></use>
            </svg>
          </div>
          <h2 className="text">Oups…</h2>
          <p className="text">Nous sommes désolés mais nous ne retrouvons pas cette page.</p>

          <div className="cta-container">
            <TransitionLink to="/" className="cta">
              Retourner
              <br />à l'accueil
            </TransitionLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
